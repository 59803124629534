@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.canvas {
  width: 85% !important;
  margin: auto !important;
}

.Navbar {
  height: 75px;
  color: white;
}

.Navbar .logo {
  height: 65px;
}

.resp_menu {
  position: fixed;
  top: 0px;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  height: 100%;
  transition: 0.3s all ease;
  width: 60%;
}

.Hero {
  min-height: 100vh;
  width: 100%;
  background: rgba(0, 0, 0, 0.5) url("./images/hero.jpg");
  background-blend-mode: darken;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.Hero .heroMain {
  min-height: 100vh;
}

.Hero .grid {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 20px;
}

.heroimg {
  height: 70%;
}

/* Address */
.cards-grid {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  justify-content: center;
  grid-column-gap: 50px;
  grid-row-gap: 60px;
  margin-top: 80px;
}

.Card {
  position: relative;
  height: 250px;
  background: #162058;
  padding: 0px 20px;
  border-radius: 5px;
  position: relative;
}

.Card .icon {
  position: absolute;
  top: -45px;
  height: 90px;
  width: 90px;
  border-radius: 50%;
  background: blue;
  color: white !important;
}

.Card .fw-bold {
  letter-spacing: 3px;
  position: relative;
}

.Address .Card .fw-bold::after {
  content: "";
  position: absolute;
  top: 100%;
  margin-top: 2px;
  left: calc(50% - 40px);
  width: 80px;
  height: 3px;
  background: #ffd683;
}

/* Services */
.Services {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #162058;
  padding: 40px 20px;
}

.Services .Card {
  background: rgb(248, 248, 248);
  color: black !important;
  transition: 0.3s all ease;
}

.Services .Card:hover {
  background: white;
}

/* rRws */
.details {
  position: relative;
}

.details::before {
  content: "";
  position: absolute;
  top: 0%;
  margin-top: -20px;
  left: 0px;
  width: 80px;
  height: 4px;
  background: blue;
}

.RowSection img {
  box-shadow: 10px 10px 7px 3px rgb(196, 196, 196);
  object-fit: cover;
}

/* worksection */
.worksection {
  background: #162058;
  padding: 40px 20px;
}

.small-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  justify-content: center;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  margin-top: 80px;
}

/* Team */
.Team .avatar {
  display: block;
  margin: auto;
  border-radius: 50%;
  height: 150px;
  width: 150px;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-bottom: 0px !important;
}

.primary_btn {
  background: blue !important;
  color: white;
  transition: 0.3s all ease;
}

.highlight {
  color: #ffd683;
}

.primary_btn:hover {
  opacity: 0.7;
}

.main_heading {
  font-size: 3rem;
}

.heading_style {
  position: relative;
}

.heading_style::after {
  content: "";
  position: absolute;
  top: 100%;
  margin-top: 10px;
  left: calc(50% - 5%);
  width: 10%;
  height: 3px;
  background: blue;
}

.mt-10 {
  margin-top: 70px !important;
}

.blue {
  color: blue;
}

/* accordian */
.accordian-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: #162058;
  color: white;
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.accordian-container .heading h2 {
  font-size: 3rem;
}

.line {
  width: 6rem;
  height: 0.5rem;
  background-color: #f7ffa4;
}

.text-large {
  font-size: 1.5rem;
  font-family: "Lato", Sans-serif;
  font-size: 25px;
  font-weight: 600;
  color: #54595f;
}

.small-section {
  padding: 20px 0px;
}

.small-section .heading p {
  color: #001c31;
  font-family: "Lato", Sans-serif;
  font-size: 18px;
  line-height: 1.2em;
  letter-spacing: 3px;
}

.small-section .heading h2 {
  color: #001c31;
  font-family: "Lato", Sans-serif;
  font-size: 42px;
  font-weight: bold;
  line-height: 1.2em;
}

.small-section .heading .btn {
  box-shadow: 5px 5px 10px 0px rgb(0 0 0 / 15%);
}

.small-section .heading .btn:hover {
  opacity: 1;
  color: white;
  transform: scale(1.2);
  transition: 0.2s;
}

footer {
  background-color: #54595f;
  color: white;
}

footer img {
  width: 43%;
  opacity: 1;
}

footer p {
  font-size: 0.8rem;
}

.footer .modal .modal-body {
  height: 70vh;
  overflow-y: scroll;
}

.fBtn {
  background: #fff url(//cdn.iubenda.com/icon_green_mini.png) no-repeat;
  background-size: auto 14px;
  background-position: 7px 3px;
  background-position-x: 6px;
  background-position-y: 2px;
  padding-left: 20px;
  text-decoration: none;
  color: #59636d;
  border: none;
  border-radius: 6px;
  font-size: 0.8rem;
  width: 7rem;
  box-shadow: 0 1px 0 rgb(0 0 0 / 8%);
}

.icon {
  background-color: white;
  color: gray;
  border-radius: 6px;
  cursor: pointer;
  z-index: 0;
}

.icon:hover {
  background-color: rgba(128, 128, 128, 0.347);
}

.product-section {
  background-color: #162058;
  color: white;
}

.card-container {
  color: black;
  justify-content: space-around;
  align-items: center;
}

.Pcard-content {
  background-color: white;
}

.Pdetails h6 {
  color: white;
}

.Pdetails span {
  color: green;
}

.Questions {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #f28a0c;
  background: linear-gradient(180deg, #ffd683, #fde0cc);
  overflow: hidden !important;
}

.Questions .bg {
  background: url(https://sunprime.it/terreni-agricoli/static/media/nuvola-doppia.05dd18fc.png);
  height: 90vh;
  width: 100%;
  position: fixed;
  top: 10vh;
  opacity: 0.8;
  pointer-events: none;
  transition: 0.2s all ease;
}

.Questions .question {
  position: relative;
  height: 90vh;
  width: 60%;
  padding: 20px 40px 20px 40px;
  margin-left: auto;
  border-left: 2px solid white;
}

.Questions #animated_div {
  position: absolute;
  bottom: 0%;
  height: 90vh;
  animation: animateQuestion 0.7s linear;
  overflow: visible;
}

.gm-style-mtc,
.gm-style-mtc {
  display: none;
}

.grid_2 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 30px;
}

.ContactForm {
  padding: 40px 20px;
}

@keyframes animateQuestion {
  0% {
    bottom: -100%;
  }
  25% {
    bottom: -75%;
  }
  50% {
    bottom: -50%;
  }
  75% {
    bottom: -25%;
  }
  100% {
    bottom: 0%;
  }
}

.Questions .main-question {
  height: 45%;
}

.Questions .answer {
  border: none;
  outline: none;
  font-size: 1.5rem;
  width: 70px;
  height: 50px;
  background: transparent;
  border-radius: 5px;
  z-index: 200;
  position: relative;
}

.Questions .answer:hover {
  background: white;
}

.Questions .answer::before {
  content: "";
  width: 12px;
  height: 12px;
  position: absolute;
  left: -48px;
  top: calc(50% - 7px);
  box-shadow: 0px 0px 15px 2px rgb(216, 216, 216);
  background-color: white;
  border-radius: 50%;
}

.Spirit {
  padding: 40px 20px;
}

.Spirit .info {
  width: 40%;
}

.img_grid {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 20px;
  width: 58%;
  margin-bottom: -100px;
}

.img_grid img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  border-radius: 3px;
}

.big_img {
  grid-row: span 2 / span 2;
}

/* CONSULT */
.consult_form {
  width: 60%;
  margin: auto;
  padding: 20px;
}

.consult_form .name {
  width: 50%;
}

.bg_title {
  background: #162058;
  padding-top: 10px;
  padding-bottom: 50px;
  text-align: center;
}

.bg_gray {
  background: rgb(247, 247, 247);
}

@media (max-width: 1000px) {
  .Spirit_grid {
    flex-direction: column-reverse;
  }
  .img_grid {
    margin-bottom: 20px;
    width: 100%;
  }
  .Spirit_grid .info {
    width: 100%;
  }
}

@media (max-width: 850px) {
  .RowSection .canvas {
    flex-direction: column !important;
  }
  .RowSection .canvas .w-50 {
    width: 100% !important;
  }
  .RowSection .canvas img {
    height: 50% !important;
    margin-bottom: 40px;
  }
}

@media (min-width: 750px) {
  .footer .modal .modal-dialog {
    max-width: 75% !important;
  }
}

@media (max-width: 800px) {
  .consult_form {
    width: 90%;
  }
  .consult_form .nameBox{
    flex-direction: column;
  }
  .consult_form .name{
    width: 100%;
  }
  .consult_form .name:last-child{
    margin-top: 12px;
  }
  .main_heading {
    font-size: 2.5rem;
  }
  .heroimg {
    display: none !important;
  }
  .Hero .content {
    width: 100% !important;
  }
  .Team .avatar {
    height: 100px;
    width: 100px;
  }
  .Questions .question {
    width: 80%;
  }
  .email_form {
    width: 80% !important;
  }
  .grid_2 img {
    height: 300px !important;
  }

}

@media (max-width: 400px) {
  .consult_form {
    width: 92%;
  }
  .canvas {
    width: 90% !important;
  }
  .logo {
    width: 180px;
  }
  .main_heading {
    font-size: 2.2rem;
  }
  .Questions .question {
    width: 95%;
  }
  #openMap {
    width: 85% !important;
  }
}

@media (max-width: 365px) {
  .canvas {
    width: 93% !important;
  }
  .main_heading {
    font-size: 2rem;
  }
}

.bg_primary {
  background: #162058;
}
